import React, { Component } from "react";


class Blog extends Component{
    render(){

        let data = [
            {
                desc: 'La aplicación nos ha permitido optimizar el tratamiento químico y reducir el tiempo para el procesamiento de la información, logrando que nos enfoquemos en actividades de mayor valor agregado.',
                image: "opinion-1.svg",
                postBy:'Carlos',
                position: 'Supervisor de tratamiento químico e ingeniería mecánica'
            },
            
            {
                desc: 'La aplicación tiene un potencial comercial muy importante debido al uso de analítica predictica e inteligencia artificial para identificar problemas de aseguramiento de flujo.',
                image: "opinion-1.svg",
                postBy:'Jorge',
                position: 'Director ejecutivo'
            },
            {
                desc: 'La plataforma genera alertas tempranas de posibles problemas de corrosión e incrustaciones en líneas de producción, y permite optimizar el tratamiento químico y reducir sus costos operacionales, convirtiéndose en una ayuda valiosa para la toma de decisiones y la resolución de problemas',
                image: "opinion-2.svg",
                postBy:'Mónica',
                position: 'Ingeniera de Tratamiento Químico'
            },
        ];
        let DataList = data.map((val, i) => {
            return(
                <div className="opinion-container col-lg-4 col-md-6 col-12" key={i}>
                    <div className="opinion">
                        <div className="description">
                            <div className="quote-start"></div>
                            <p className="desc-text">{val.desc}</p>
                        </div>
                        <div className="person">
                            <div className="person-picture">
                                <img src={require(`../assets/images/opinions/${val.image}`).default} alt={val.postBy}/>
                            </div>
                            <h4 className="theme-color">{val.postBy}</h4>
                        </div>
                    </div>
                    <div className="position">
                        <p>{val.position}</p>
                    </div>
                </div>
            )
        });
        return(
            <div className={"opinions-area"} id="opinions">
                <div className="container" id="opinions-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>Conoce la <span className="theme-color">opinión</span> de nuestros usuarios</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {DataList}
                    </div>
                </div>
            </div>
        )
    }
}


export default Blog;

