import React, { Component } from "react";


class Introduction extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home"  style={{background:'#2d3e50'}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-6 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1>
                                        Plataforma digital de <br /><br />
                                        <span className="app-name theme-color">Aseguramiento de flujo en pozos petroleros </span><br /><br />
                                        Para prevenir eventos de corrosión e incrustaciones, incrementar el run-life y optimizar su producción
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-5 offset-xl-1 h-100">
                            <div className="contact-inner">
                                <div className="contact-inner-table">
                                    <h3 className="title">
                                        Registrate para un<br />
                                        <span className="app-name theme-color">DEMO GRATIS</span>
                                    </h3>
                                    <form className="contact-form" action="/demo">
                                        <div className="input-box">
                                            <input type="text" placeholder="Nombre"/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" placeholder="Email"/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" placeholder="Número de Teléfono"/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" placeholder="Empresa"/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" placeholder="Cargo"/>
                                        </div>

                                        <div className="input-box">
                                            <button className="submit-button" type="submit">Registro</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Introduction;








