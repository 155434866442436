import React, {Component, Fragment} from "react";
import DemoForm from '../components/DemoForm';
import FooterSmall from '../components/FooterSmall';
import NavbarSmall from '../components/NavbarSmall';

class Demo extends Component {
    render() {
        return (
            <Fragment>
                {/* Header Navbar */}
                <NavbarSmall/>

                {/* Main */}
                <DemoForm/>

                {/* Footer */}
                <FooterSmall/>
            </Fragment>
        )
    }
}

export default Demo;

