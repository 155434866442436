import Introduction from '../components/Introduction';

import Benefits from '../components/Benefits';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import React, {Component} from "react";
import Opinions from '../components/Opinions';

class Home extends Component {
    render() {
        return (
            <div>
                {/* Header Navbar */}
                <Navbar/>

                {/* Main */}
                <Introduction bgshape="bg-shape" horizontal=""/>

                {/* Benefits */}
                <Benefits/>

                {/* Opinions */}
                <Opinions/>

                {/* Footer */}
                <Footer/>
            </div>
        )
    }
}

export default Home;

