import React , { Component } from "react";
class FooterSmall extends Component {
    render(){
        return(
            <div>
                <div className={"footer-area footer-small"} id="contact">
                    <div className="footer-bg"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--40">
                                    <h3 className="text-white">Descubre cómo podemos ayudarte a optimizar el tratamiento químico en pozos y capitalizar el potencial de tus datos</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row contact-button-container">
                            <div className="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3">
                                <button className="submit-button">
                                    <a href="mailto:pungosolutions@gmail.com?subject=Proyecto%20plataforma%20digital%20de%20aseguramiento%20de%20flujo%20en%20pozos%20petroleros
                                    &body=Hola,%0D%0A%0D%0A
                                    Estoy interesado en informaci&oacute;n sobre este proyecto.%0D%0A%0D%0A
                                    Saludos,%0D%0A
                                    ">Contáctate con nosotros</a>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 offset-xl-4 col-lg-8 offset-lg-2">
                                <div className="footer-links text-center">
                                    <a href="/"><i className="zmdi zmdi-facebook"></i></a>
                                    <a href="/"><i className="zmdi zmdi-twitter"></i></a>
                                    <a href="/"><i className="zmdi zmdi-google"></i></a>
                                    <a href="/"><i className="zmdi zmdi-linkedin"></i></a>
                                    <a href="/"><i className="zmdi zmdi-pinterest"></i></a>
                                    <a href="/"><i className="zmdi zmdi-youtube"></i></a>
                                </div>
                                <div className="footer-text text-center">
                                    <span>Copyright © {new Date().getFullYear()} <a href="/">Pungo</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tap-top">
                    <div>
                        <i className="zmdi zmdi-long-arrow-up"></i>
                    </div>
                </div>
            </div>
        )
    }
}
export default FooterSmall







