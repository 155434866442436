import React ,  { Component } from "react";

class NavbarSmall extends Component {
    render(){
        return(
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7 col-6">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    <img className="logo-1" src={require('../assets/images/logo/logo.png').default} alt="app landing"/>
                                </a>
                                <div className="logo-text">Analítica predictiva e Intelligencia Artificial al servicio de la industria petrolera</div>
                                <div className="logo-text-small theme-color">Pungo</div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                        <li className="active"><a href={`${process.env.PUBLIC_URL}/`}>Inicio</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#benefits`}>Beneficios</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#opinions-container`}>Opiniones</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#contact`}>Contáctanos</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-5 col-6 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>                              
                                        <li className="active"><a href={`${process.env.PUBLIC_URL}/`}>Inicio</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#benefits`}>Beneficios</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#opinions-container`}>Opiniones</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#contact`}>Contáctanos</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavbarSmall;


