import React ,  { Component } from "react";
import { XAxis, YAxis, Tooltip, BarChart, Bar, Cell, Label } from "recharts";
import { scaleLog } from 'd3-scale';

class ConsolidatedWellResults extends Component {
    risk_color = {
        0: '#387908',
        1: 'yellow',
        2: '#fd7e14',
        3: 'red'
    }

    scale = scaleLog().base(10).nice();

    render(){
        return(
            <div className="consolidated-well-results">
                <div className="row result-container justify-content-center">
                    <div className="result-header"><h3>Niveles de Criticidad</h3></div>
                    <div className="table-wrapper">
                        <div className="table-row table-header">
                            <div className="table-col">Nombre del Pozo</div>
                            <div className="table-col">Índice de criticidad</div>
                            <div className="table-col">Nivel de prioridad</div>
                        </div>
                        {this.props.consolidated_results.criticalness_levels.map(function(criticalness_level, i) {
                            return <div className="table-row" key={i}>
                                <div className="table-col">{criticalness_level.well_name}</div>
                                <div className="table-col">{criticalness_level.criticalness_index}</div>
                                <div className="table-col">{criticalness_level.priority_level}</div>
                            </div>;
                        })}
                    </div>
                </div>
                <div className="row result-container justify-content-center">
                    <div className="result-header"><h3>Índice de Criticidad</h3></div>
                    <div className='line-chart-wrapper'>
                        <BarChart
                            width={680}
                            height={400}
                            data={this.props.consolidated_results.criticalness_indexes}
                        >
                            <XAxis dataKey="well_name" type="category" stroke="white" height={80}>
                                <Label value="Pozos" offset={18} fill={'white'} fontSize="20"/>
                            </XAxis>
                            <YAxis dataKey="index" type="number" allowDecimals={false} stroke="white" width={80}>
                                <Label value="Nivel de Criticidad" position="center" angle={270} fill={'white'} fontSize="20"/>
                            </YAxis>
                            <Tooltip/>
                            <Bar dataKey="index" fill="#387908" name="Nivel de Criticidad">
                            {
                                this.props.consolidated_results.criticalness_indexes.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.risk_color[parseFloat(entry.priority_level_number).toFixed(0)]}/>
                                ))
                            }
                            </Bar>
                        </BarChart>
                    </div>
                </div>  
            </div>
        )
    }
}

export default ConsolidatedWellResults;











