import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Demo from './pages/Demo';

const App = () => {
    return (
        <div className="App">
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Demo}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;