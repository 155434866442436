import React ,  { Component } from "react";

class Benefits extends Component{
    render(){
        return(
            <div className={"app-benefits"}  id="benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>Conoce los <span className="theme-color">Beneficios</span> de usar nuestra aplicación</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="benefits-item">
                                <div className="benefits-image mt-md-3 mt-2">
                                    <img className="image" src={require('../assets/images/benefits/benefits.svg').default} alt="Beneficios económicos"/>
                                </div>
                                <div className="benefits-content ps-xl-5 ps-lg-4 ps-md-5 ps-4 pe-xl-5 pe-lg-3 pe-md-5 pe-sm-3 pe-1">
                                    <h2 className="title">Beneficios económicos</h2>
                                    <p>Reduce los costos asociados al tratamiento químico y las pérdidas de producción por eventos de corrosión e incrustaciones</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="benefits-item">
                                <div className="benefits-image mt-md-3 mt-2">
                                    <img className="image" src={require('../assets/images/benefits/security.svg').default} alt="Beneficios económicos"/>
                                </div>
                                <div className="benefits-content ps-xl-5 ps-lg-4 ps-md-5 ps-4 pe-xl-5 pe-lg-3 pe-md-5 pe-sm-3 pe-1">
                                    <h2 className="title">Incrementa la seguridad de las operaciones</h2>
                                    <p>Recibe alertas para prevenir pérdidas de producción y optizar la confiabilidad y seguridad de tus operaciones</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="benefits-item">
                                <div className="benefits-image mt-md-3 mt-2">
                                    <img className="image" src={require('../assets/images/benefits/critical.svg').default} alt="Beneficios económicos"/>
                                </div>
                                <div className="benefits-content ps-xl-5 ps-lg-4 ps-md-5 ps-4 pe-xl-5 pe-lg-3 pe-md-5 pe-sm-3 pe-1">
                                    <h2 className="title">Identifica los pozos críticos de manera automática</h2>
                                    <p>Identifica los pozos críticos en tus operaciones con nuestra metodología para priorizar y optimizar el tratamiento químico</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="benefits-item">
                                <div className="benefits-image mt-md-3 mt-2">
                                    <img className="image" src={require('../assets/images/benefits/digital.svg').default} alt="Beneficios económicos"/>
                                </div>
                                <div className="benefits-content ps-xl-5 ps-lg-4 ps-md-5 ps-4 pe-xl-5 pe-lg-3 pe-md-5 pe-sm-3 pe-1">
                                    <h2 className="title">Digitaliza la información de tus operaciones</h2>
                                    <p>Disminuye el tiempo para el procesamiento de datos, genera reportes automáticos y libera tiempo valioso para optimizar la rentabilidad y seguridad de las operaciones</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Benefits;











