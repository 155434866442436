import React, { Component } from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ConsolidatedWellResults from "./demo_form_components/ConsolidatedWellResults";
import SingleWellResults from "./demo_form_components/SingleWellResults";

class DemoForm extends Component {
    state = {}
    notification_timeout = 4000

    // notification types
    notification_type = {
        'info': 'info',
        'success': 'success',
        'warning': 'warning',
        'error': 'error'
    }

    risk_levels = {
        0: "Bajo",
        1: "Medio",
        2: "Alto",
        3: "Muy Alto"
    }

    constructor(props) {
        super(props);
        this.state = {
            file: '',
            well_results: [],
            consolidated_results: { criticalness_indexes: [], criticalness_levels: [] }
        };
    }

    /*
    * Clear button action
    */
    clean = () => {
        var empty_well_results = [];
        var empty_consolidated_results = { criticalness_indexes: [], criticalness_levels: [] };
        this.setState({ 'well_results': Object.assign({}, empty_well_results) });
        this.setState({ 'consolidated_results': Object.assign({}, empty_consolidated_results) });

        // clean file input by reference
        this.fileInput.value = "";
    }

    setSelectedFile = (e) => {
        const readFile = e.target.files[0];
        this.setState({
            'file': readFile
        });
    }

    submitForm = () => {
        if(this.validateForm()) {
            let fileBase64 = '';
            this.getBase64(this.state['file'], (result) => {
                fileBase64 = result;
                console.log(JSON.stringify({
                    'encoded_data': fileBase64,
                }));
                // clean file input by reference
                this.fileInput.value = "";
                fetch(process.env.REACT_APP_API_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'encoded_data': fileBase64,
                    })
                }).then(res => res.json())
                    .then(
                        (result) => {
                            console.table(result);
                            let message;
                            let color = this.notification_type.success;
    
                            if (typeof result['0'] == 'undefined' || typeof result['0'].mpy_depth == 'undefined') {
                                color = this.notification_type.error;
                                if (typeof result.mensaje != 'undefined') {
                                    message = result.mensaje;
                                } else {
                                    message = "Error con el cálculo, revise los valores ingresados.";
                                }
                                this.showMessage(message, color);
                            } else {
                                // successful response
                                try {
                                    this.process_data(result);
                                } catch (error) {
                                    console.error(error);
                                    this.showMessage("Transformación de respuesta inválida", this.notification_type.error);
                                }
                            }
                        },
                        (error) => {
                            let errorMessage = '';
                            if (typeof error.message != "undefined" && typeof error.stack != "undefined") {
                                errorMessage = error.message + '\n' + error.stack;
                            } else {
                                errorMessage = 'Error: ' + JSON.stringify(error);
                            }
                            this.showMessage(errorMessage, this.notification_type.error);
                        }
                    )
            }, this.showMessage);
        }
    };

    validateForm = () => {
        let allValid = true;
        let message = "";

        if (isNaN(this.fileInput.value)) {
            if (this.fileInput.size > 10485760) {
                allValid = false;
                message += "El archivo no puede tener un tamaño mayor a 10 MB";
            }
        } else {
            allValid = false;
            message += "Seleccione un archivo para proceder";
        }

        if (!allValid) {
            this.showMessage(message, this.notification_type.error);
        }
        return allValid;
    }

    showMessage = (message, type) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message, 'Información', this.notification_timeout);
                break;
            case 'success':
                NotificationManager.success(message, 'Correcto', this.notification_timeout);
                break;
            case 'warning':
                NotificationManager.warning(message, 'Advertencia', this.notification_timeout);
                break;
            case 'error':
                NotificationManager.error(message, 'Error!', this.notification_timeout);
                break;
        }
    }

    getBase64(file, callback, mesageFunction) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result.substring(reader.result.indexOf(';base64') + 7))
        };
        reader.onerror = function (error) {
            mesageFunction("Archivo no pude ser cargado. Revise los permisos!", "error");
            console.log('Error: ', error);
        };
    }

    process_data = (input_data) => {
        var new_well_results = [];
        var new_consolidated_results = { criticalness_indexes: [], criticalness_levels: [] };

        for(let i = 0; i < input_data.length; i++) {
            new_well_results[i] = { lineal_data:[], results: {} };
            new_consolidated_results.criticalness_indexes[i] = { well_name: "", index: 0, priority_level_number: 0 }
            new_consolidated_results.criticalness_levels[i] = { well_name: "", criticalness_index: 0, priority_level: "" }

            new_well_results[i].results.well_name = input_data[i].well_names;
            // corrosion rate
            new_well_results[i].results.mpy_head = typeof input_data[i].mpy_head !== "undefined" ? parseFloat(input_data[i].mpy_head.toFixed(2)) : 0;
            new_well_results[i].results.mpy_depth = typeof input_data[i].mpy_depth !== "undefined" ? parseFloat(input_data[i].mpy_depth.toFixed(2)) : 0;
            new_well_results[i].results.corrosion_risk_head = typeof input_data[i].corrosion_risk_head !== "undefined" ? this.risk_levels[input_data[i].corrosion_risk_head.toFixed(0)] : 0;
            new_well_results[i].results.corrosion_risk_depth = typeof input_data[i].corrosion_risk_depth !== "undefined" ? this.risk_levels[input_data[i].corrosion_risk_depth.toFixed(0)] : 0;

            // saturation index
            new_well_results[i].results.scale_head = typeof input_data[i].scale_head !== "undefined" ? parseFloat(input_data[i].scale_head.toFixed(2)) : 0;
            new_well_results[i].results.scale_depth = typeof input_data[i].scale_depth !== "undefined" ? parseFloat(input_data[i].scale_depth.toFixed(2)) : 0;
            new_well_results[i].results.scale_risk_head = typeof input_data[i].scale_risk_head !== "undefined" ? this.risk_levels[input_data[i].scale_risk_head.toFixed(0)] : 0;
            new_well_results[i].results.scale_risk_depth = typeof input_data[i].scale_risk_depth !== "undefined" ? this.risk_levels[input_data[i].scale_risk_depth.toFixed(0)] : 0;

            // corrosion profile & saturation index profile
            for(let j = 0; j < input_data[i].pipe_head_depth.length; j++) {
                new_well_results[i].lineal_data[j] = {};

                new_well_results[i].lineal_data[j].pipe_head_depth = typeof input_data[i].pipe_head_depth !== "undefined" ? parseFloat(input_data[i].pipe_head_depth[j].toFixed(2)) : 0;
                new_well_results[i].lineal_data[j].temperature_head_depth = typeof input_data[i].temperature_head_depth !== "undefined" ? parseFloat(input_data[i].temperature_head_depth[j].toFixed(2)) : 0;
                new_well_results[i].lineal_data[j].pressure_head_depth = typeof input_data[i].pressure_head_depth !== "undefined" ? parseFloat(input_data[i].pressure_head_depth[j].toFixed(2)) : 0;
                new_well_results[i].lineal_data[j].mpy_head_depth = typeof input_data[i].mpy_head_depth !== "undefined" ? parseFloat(input_data[i].mpy_head_depth[j].toFixed(2)) : 0;
                new_well_results[i].lineal_data[j].co2_fugacity_head_depth = typeof input_data[i].co2_fugacity_head_depth !== "undefined" ? parseFloat(input_data[i].co2_fugacity_head_depth[j].toFixed(2)) : 0;
                new_well_results[i].lineal_data[j].calcite_head_depth = typeof input_data[i].calcite_head_depth !== "undefined" ? parseFloat(input_data[i].calcite_head_depth[j].toFixed(2)) : 0;
                new_well_results[i].lineal_data[j].ptb_head_depth = typeof input_data[i].ptb_head_depth !== "undefined" ? parseFloat(input_data[i].ptb_head_depth[j].toFixed(2)) : 0;
            }

            // consolidated results
            new_consolidated_results.criticalness_indexes[i].well_name = typeof input_data[i].well_names !== "undefined" ? input_data[i].well_names : "";
            new_consolidated_results.criticalness_indexes[i].index = typeof input_data[i].criticalness_index !== "undefined" ? parseFloat(input_data[i].criticalness_index.toFixed(2)) : 0;
            new_consolidated_results.criticalness_indexes[i].priority_level_number = typeof input_data[i].priority_level !== "undefined" ? input_data[i].priority_level : 0;
            
            new_consolidated_results.criticalness_levels[i].well_name = typeof input_data[i].well_names !== "undefined" ? input_data[i].well_names : "";
            new_consolidated_results.criticalness_levels[i].criticalness_index = typeof input_data[i].criticalness_index !== "undefined" ? input_data[i].criticalness_index.toFixed(2) : 0;
            new_consolidated_results.criticalness_levels[i].priority_level = this.risk_levels[new_consolidated_results.criticalness_indexes[i].priority_level_number];
            
        }

        this.setState({ 'well_results':  new_well_results});
        this.setState({ 'consolidated_results':  new_consolidated_results});
        
    }

    render(){
        return(
            <div className={`demo-content ${this.props.bgshape}`} id="demo-form" style={{background:'#2d3e50'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-sm-12">
                            <div className="contact-inner">
                                <div className="contact-inner-table">
                                    <h3 className="title text-center">Demo de Pozos Petroleros</h3>
                                    <div className="demo-form" action="/">
                                        <div className="input-box">
                                            <input type="file" placeholder="Archivo" ref={ref=> this.fileInput = ref} onChange={this.setSelectedFile}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row button-container">
                        <div className="input-box col-xl-3 offset-xl-3 col-lg-4 offset-lg-2 col-md-5 offset-md-1">
                            <button className="button calc-button" onClick={this.submitForm}>Procesar</button>
                        </div>
                        <div className="input-box col-xl-3 col-lg-4 col-md-5">
                            <button className="button clean-button" onClick={this.clean}>Limpiar</button>
                        </div>
                    </div>
                    <div className="results-wrapper">
                        {this.state.well_results.length > 0 ?
                            <Tabs>
                                <TabList>
                                    {this.state.well_results.map(function(well_result, i) {
                                        return <Tab key={i}><h3>{"Pozo " + well_result.results.well_name}</h3></Tab>;
                                    })}
                                    <Tab><h3>Criticidad de Pozos</h3></Tab>
                                </TabList>
                                {this.state.well_results.map(function(well_result, i) {
                                    return <TabPanel key={i}><SingleWellResults well_result={well_result} index={i} key={i}/></TabPanel>;
                                })}
                                {this.state.consolidated_results.criticalness_indexes.length > 0 ?
                                    <TabPanel><ConsolidatedWellResults consolidated_results={this.state.consolidated_results} /></TabPanel>
                                :""}
                            </Tabs>
                        :""}
                    </div>
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}

export default DemoForm;








