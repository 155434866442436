import React ,  { Component } from "react";
import { Line, LineChart, XAxis, YAxis, Tooltip, Label } from "recharts";

class SingleWellResults extends Component {
    CorrosionTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(45, 62, 80)"}}>
                        <span className="recharts-tooltip-item-name">Profundidad</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].payload.pipe_head_depth}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(45, 62, 80)"}}>
                        <span className="recharts-tooltip-item-name">Temperatura</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].payload.temperature_head_depth}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(45, 62, 80)"}}>
                        <span className="recharts-tooltip-item-name">Presión</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].payload.pressure_head_depth}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(255, 115, 0)"}}>
                        <span className="recharts-tooltip-item-name">Velocidad de Corrosión</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].value}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(56, 121, 8)"}}>
                        <span className="recharts-tooltip-item-name">Fugacidad CO2</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[1].value}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                </ul>
            </div>
          );
        }
        return null;
    };

    SaturationTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(45, 62, 80)"}}>
                        <span className="recharts-tooltip-item-name">Profundidad</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].payload.pipe_head_depth}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(45, 62, 80)"}}>
                        <span className="recharts-tooltip-item-name">Temperatura</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].payload.temperature_head_depth}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(45, 62, 80)"}}>
                        <span className="recharts-tooltip-item-name">Presión</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].payload.pressure_head_depth}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(255, 115, 0)"}}>
                        <span className="recharts-tooltip-item-name">Índice de Saturación Calcita</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[0].value}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                    <li className="recharts-tooltip-item" style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: "rgb(56, 121, 8)"}}>
                        <span className="recharts-tooltip-item-name">Sólidos PTB</span>
                        <span className="recharts-tooltip-item-separator"> : </span>
                        <span className="recharts-tooltip-item-value">{payload[1].value}</span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                </ul>
            </div>
          );
        }
        return null;
    };

    render(){
        return(
            <div className="single-well-results" id={"well_result_" + this.props.index}>
                <div className="row result-container justify-content-center">
                        <div className="result-header"><h3>Perfil de Velocidad de Corrosión</h3></div>
                        <div className="table-wrapper">
                            <div className="table-row table-header">
                                <div className="table-col">Lugar</div>
                                <div className="table-col">Velocidad de corrosión [mpy]</div>
                                <div className="table-col">Nivel de riesgo</div>
                            </div>
                            <div className="table-row">
                                <div className="table-col">Cabeza</div>
                                <div className="table-col">{this.props.well_result.results.mpy_head}</div>
                                <div className="table-col">{this.props.well_result.results.corrosion_risk_head}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-col">Fondo</div>
                                <div className="table-col">{this.props.well_result.results.mpy_depth}</div>
                                <div className="table-col">{this.props.well_result.results.corrosion_risk_depth}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row result-container justify-content-center">
                        <div className="result-header"><h3>Perfil de Corrosión</h3></div>
                        <div className='line-chart-wrapper'>
                            <LineChart width={860} height={480} data={this.props.well_result.lineal_data}>
                                <YAxis dataKey='mpy_head_depth' type='number' width={80} yAxisId={0} stroke="#ff7300">
                                    <Label value="Velocidad de Corrosión [mpy]" position="center" angle={270} fill={'white'} fontSize="18"/>
                                </YAxis>
                                <YAxis dataKey='co2_fugacity_head_depth' orientation='right' width={80} yAxisId={1} allowDecimals={false} stroke="#387908">
                                    <Label value="Fugacidad CO2 [psi]" position="center" angle={270} fill={'white'} fontSize="18"/>
                                </YAxis>
                                <XAxis dataKey='pipe_head_depth' orientation="top" height={60} xAxisId={0} type='number' domain={['dataMin', 'dataMax']} stroke="white" tickSize={10}>
                                    <Label value="Profundidad [ft]" position="insideTop" offset={18} fill={'white'} fontSize="18"/>
                                </XAxis>
                                <XAxis dataKey='temperature_head_depth' orientation="top" height={60} xAxisId={1} type='number' domain={['dataMin', 'dataMax']} stroke="white">
                                    <Label value="Temperatura [F]" position="insideTop" offset={20} fill={'white'} fontSize="18"/>
                                </XAxis>
                                <XAxis dataKey='pressure_head_depth' height={60} xAxisId={2} type='number' domain={['dataMin', 'dataMax']} stroke="white" tickSize={10}>
                                    <Label value="Presión [psi]" position="insideBottom" offset={18} fill={'white'} fontSize="18"/>
                                </XAxis>
                                <Tooltip content={<this.CorrosionTooltip />} />
                                <Line dataKey='mpy_head_depth' stroke='#ff7300' strokeWidth={2} dot={{ r: 1.5 }} xAxisId={0} yAxisId={0}/>
                                <Line dataKey='co2_fugacity_head_depth' stroke='#387908' strokeWidth={2} dot={{ r: 1.5 }} xAxisId={0} yAxisId={1}/>
                            </LineChart>
                        </div>
                    </div>
                    <div className="row result-container justify-content-center">
                        <div className="result-header"><h3>Índice de Saturación</h3></div>
                        <div className="table-wrapper">
                            <div className="table-row table-header">
                                <div className="table-col">Lugar</div>
                                <div className="table-col">Índice de Saturación</div>
                                <div className="table-col">Riesgo</div>
                            </div>
                            <div className="table-row">
                                <div className="table-col">Cabeza</div>
                                <div className="table-col">{this.props.well_result.results.scale_head}</div>
                                <div className="table-col">{this.props.well_result.results.scale_risk_head}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-col">Fondo</div>
                                <div className="table-col">{this.props.well_result.results.scale_depth}</div>
                                <div className="table-col">{this.props.well_result.results.scale_risk_depth}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row result-container justify-content-center">
                        <div className="result-header"><h3>Perfil del Índice de Saturación</h3></div>
                        <div className='line-chart-wrapper'>
                            <LineChart width={860} height={480} data={this.props.well_result.lineal_data}>
                                <YAxis dataKey='calcite_head_depth' type='number' width={120} yAxisId={0} stroke="#ff7300">
                                    <Label value="Índice de Saturación Calcita" position="center" angle={270} fill={'white'} fontSize="18"/>
                                </YAxis>
                                <YAxis dataKey='ptb_head_depth' orientation='right' width={80} yAxisId={1} allowDecimals={false} stroke="#387908">
                                    <Label value="Sólidos PTB" position="center" angle={270} fill={'white'} fontSize="18"/>
                                </YAxis>
                                <XAxis dataKey='pipe_head_depth' orientation="top" height={60} xAxisId={0} type='number' stroke="white" domain={['dataMin', 'dataMax']} tickSize={10}>
                                    <Label value="Profundidad [ft]" position="insideTop" offset={18} fill={'white'} fontSize="18"/>
                                </XAxis>
                                <XAxis dataKey='temperature_head_depth' orientation="top" height={60} xAxisId={1} type='number' stroke="white" domain={['dataMin', 'dataMax']}>
                                    <Label value="Temperatura [F]" position="insideTop" offset={20} fill={'white'} fontSize="18"/>
                                </XAxis>
                                <XAxis dataKey='pressure_head_depth' height={60} xAxisId={2} type='number' stroke="white" domain={['dataMin', 'dataMax']} tickSize={10}>
                                    <Label value="Presión [psi]" position="insideBottom" offset={18} fill={'white'} fontSize="18"/>
                                </XAxis>
                                <Tooltip content={<this.SaturationTooltip />} />
                                <Line dataKey='calcite_head_depth' stroke='#ff7300' strokeWidth={2} dot={{ r: 1.5 }} xAxisId={0} yAxisId={0}/>
                                <Line dataKey='ptb_head_depth' stroke='#387908' strokeWidth={2} dot={{ r: 1.5 }} xAxisId={0} yAxisId={1}/>
                            </LineChart>
                        </div>
                    </div>
            </div>
        )
    }
}

export default SingleWellResults;











